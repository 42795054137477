import {
    INIT_PATIENT,
    FETCH_PATIENT,
    FETCH_PATIENT_FAILED,
    FETCH_PATIENT_SUCCESS,
    AUTHENTICATE_PATIENT_SUCCESS,
    AUTHENTICATE_PATIENT_FAILED,
    AUTHENTICATE_PATIENT,
    AUTHENTICATE_SET_PATIENT_DATA,
    AUTHENTICATE_SETUP_STEP,
    AUTHENTICATE_CLINIC_ID,
} from "state/model/patients/patient/constants";

const INIT_STATE = {
    loading: false,
    error: null,
    clinicId: null,
    step: 0,
    patient: {
        firstName: '',
        lastName: '',
        mobile: '',
    }
};

const patientReducers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_PATIENT:
            return INIT_STATE;
        case FETCH_PATIENT:
            return {
                ...state,
                loading: true,
                error: null,
            };
    
        case FETCH_PATIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                patient: action.payload.patient,
            };
    
        case FETCH_PATIENT_FAILED:
            return {
                ...state,
                patient: {},
                loading: false,
                error: action.payload.error,
            };
          
        case AUTHENTICATE_PATIENT:
            return {
                ...state,
                loading: true,
                error: null,
            };
        
        case AUTHENTICATE_PATIENT_SUCCESS:
            return {
                ...state,
                patient: action.payload.patient,
                step: 2,
                loading: false,
                error: null,
            };
            
        case AUTHENTICATE_PATIENT_FAILED: 
            return {
                ...state,
                patient: {},
                loading: false,
                step: 3,
                error: action.payload.error,
            };
        
        case AUTHENTICATE_SET_PATIENT_DATA:
            return {
                ...state,
                patient: {
                    ...state.patient,
                    [action.payload.key]: action.payload.value,
                },
            };

        case AUTHENTICATE_SETUP_STEP:
            return {
                ...state,
                step: action.payload.step,
            };
        case AUTHENTICATE_CLINIC_ID:
            return {
                ...state,
                clinicId: action.payload.clinicId,
            }
        default: 
            return { ...state };
    }
}

export default patientReducers;
export const CREATE_PATIENT_API = {
  url: `/self_register/create_by_patient_self`,
  method: 'post'
};

export const AUTHENTICATE_PATIENT_API = {
  url: `/self_register/check_patient_exist/:id`,
  method: 'post'
};

export const GENERATE_PATIENT_AND_CONSULTATION_API = {
  url: `/self_register/generate_patient_and_consultation/:id`,
  method: 'post'
}
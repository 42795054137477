import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_TRANSACTIONS } from "state/model/transactions/transactions/constants";
import { apiListTransactions } from "api/transaction/transactionApi";
import {
  listTransactionsFailed,
  listTransactionsSuccess
} from "state/model/transactions/transactions/transactionsActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* transactionsSaga() {
  yield all([
    fork(watchListTransactions),
  ]);
}

// List Transactions
export function* watchListTransactions() {
  yield takeEvery(LIST_TRANSACTIONS, sagaListTransactions);
}

export function* sagaListTransactions({ payload }) {
  try {
    const response = yield call(apiListTransactions, payload);

    yield put(listTransactionsSuccess({ transactions: response.data }));

  } catch (e) {
    yield put(listTransactionsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default transactionsSaga;

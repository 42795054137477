import _ from 'lodash';
import { extractIdFrom } from "helpers/stringUtils";

export const toCamel = (o) => {
  return _.transform(o, function(result, value, key) {
    result[_.camelCase(key)] = _.isObject(value) ? toCamel(value) : value;
  });
};

export const toSnake = (o) => {
  return _.transform(o, function(result, value, key) {
    result[_.snakeCase(key)] = _.isObject(value) ? toSnake(value) : value;
  });
};

export const isNew = (o) => {
  if (!o) {
    return true;
  }

  return isNewId(o.id);
};

export const isNewId = (id) => {
  if (!id) {
    return true;
  }

  return id === extractIdFrom(id);
};

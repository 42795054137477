import { all, call, delay, fork, put, takeLeading } from 'redux-saga/effects';
import {
    CREATE_PATIENT,
    AUTHENTICATE_PATIENT,
} from "state/model/patients/patient/constants";

import  {
    apiCreatePatient,
    apiAuthenticatePatientAPI,
}from "api/patient/PatientApi";
import {
    createPatientFailed,
    createPatientSuccess,
    authenticatePatientSuccess,
    authenticatePatientFailed,
    // initPatient,
} from "state/model/patients/patient/patientActions";
import { SAGA_WAITING_TIME } from 'globalConstants';
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
// import { generateRoute } from "helpers/routesUtils";
// import history from "routes/history";
import { toastError, toastSaved } from 'state/actions';

function* patientSaga() {
    yield all([
        fork(watchCreatePatient),
        fork(watchAuthenticatePatient),
    ]);
}

export function* watchAuthenticatePatient() {
    yield takeLeading(AUTHENTICATE_PATIENT, sagaAuthenticatePatient);
}

export function* sagaAuthenticatePatient({ payload }) {
    try {
        const response = yield call(apiAuthenticatePatientAPI, payload);
        const { patient } = response.data;
        yield put(authenticatePatientSuccess({ patient }));

    } catch (error) {
        yield put(authenticatePatientFailed({error: error}));
        // throwFrontError(error);
    }
}

// Create Patient
export function* watchCreatePatient() {
    yield takeLeading(CREATE_PATIENT, sagaCreatePatient);
}

export function* sagaCreatePatient({ payload }) {
    try {
        yield delay(SAGA_WAITING_TIME);
        const response = yield call(apiCreatePatient, payload);

        const { patient } = response.data;
        yield put(createPatientSuccess({ patient }));
        yield put(toastSaved({ name: `Patient` }));

    } catch (error) {
        yield put(createPatientFailed(generateErrorMessage(error)));
        yield put(toastError({}));
        throwFrontError(error);
    }
}

export default patientSaga;
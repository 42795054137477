import axios from 'axios';
import { toCamel } from "helpers/objectUtils";
import { toast } from "react-toastify";
import _ from 'lodash';
import { generateAuthToken } from "helpers/authUtils";

export function setToken(token) {
  axios.defaults.headers.common['Authorization'] = generateAuthToken(token);
  axios.defaults.headers.common['Subdomain'] = _.split(window._env_.APP_DOMAIN, '.', 1);
}

export function removeToken() {
  axios.defaults.headers.common['Authorization'] = '';
}

axios.defaults.transformResponse = [function (data) {
  return toCamel(JSON.parse(data));
}];

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401 && !isLoginPage() && !isLogoutPage()) {
    toast.error('Warning! You are unauthorized to do this');
  }

  return Promise.reject(error);
});

// const protocol = window._env_ && window._env_.HTTP_PROTOCOL && window._env_.HTTP_PROTOCOL === 'https' ? 'https' : 'http';
// const backendDomain = window._env_ ? window._env_.BACKEND_DOMAIN : '';
const apiUrlPrefix = window._env_ ? window._env_.API_URL_PREFIX : '';

axios.defaults.baseURL = `${apiUrlPrefix}`;

const isLoginPage = () => {
  return _.includes(window.location.href, '/account/login');
};

const isLogoutPage = () => {
  return _.includes(window.location.href, '/account/logout');
};

export default axios;

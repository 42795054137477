import _ from 'lodash';
import {
  LIST_TRANSACTIONS,
  LIST_TRANSACTIONS_FAILED,
  LIST_TRANSACTIONS_SUCCESS
} from "state/model/transactions/transactions/constants";

const INIT_STATE = {
  transactions: {},
  loading: true,
  error: null,
};

const transactionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transactions: _.mapKeys(action.payload.transactions, 'id'),
      };

    case LIST_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactions: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default transactionsReducer;

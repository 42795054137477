import React from "react";
import { INDEX_RECEIVED_NOTICES_PATH } from "routes/receivedNotice/constants";
import PrivateRoute from "routes/PrivateRoute";

const ReceivedNotices = React.lazy(() => import('pages/receivedNotices/index/ReceivedNotices'));

export const hiddenReceivedNoticeRoutes = {
  children: [
    {
      path: INDEX_RECEIVED_NOTICES_PATH,
      exact: true,
      name: 'Inbox',
      component: ReceivedNotices,
      route: PrivateRoute,
    }
  ]
}

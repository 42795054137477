import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { dashboardRoutes, hiddenDashboardRoutes } from "routes/dashboard/routes";
import { authRoutes } from "routes/auth/routes";
import { hiddenReceivedNoticeRoutes } from "routes/receivedNotice/routes";
import { giftCardRoutes, hiddenGiftCardRoutes } from "routes/giftCard/routes";
import { INDEX_GIFT_CARDS_PATH } from "routes/giftCard/constants";

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={ INDEX_GIFT_CARDS_PATH }/>,
  route: Route,
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
  console.log('flattenRoutes');
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const hiddenRoutes = {
  children: [
    ...hiddenDashboardRoutes.children,
    ...hiddenReceivedNoticeRoutes.children,
    // ...hiddenProductGiftCardRoutes.children,
    ...hiddenGiftCardRoutes.children,
  ],
};

// All routes
const allRoutes = [
  rootRoute,
  authRoutes,
  dashboardRoutes,
  // productGiftCardRoutes,
  giftCardRoutes,
  hiddenRoutes,
];

const authProtectedRoutes = [
  rootRoute,
  // productGiftCardRoutes,
  giftCardRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };

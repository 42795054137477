export const INIT_PATIENT = 'INIT_PATIENT';

export const CREATE_PATIENT = 'CREATE_PATIENT';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAILED = 'CREATE_PATIENT_FAILED';

export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILED = 'UPDATE_PATIENT_FAILED';

export const FETCH_PATIENT = 'FETCH_PATIENT';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILED = 'FETCH_PATIENT_FAILED';

export const AUTHENTICATE_PATIENT = 'AUTHENTICATE_PATIENT';
export const AUTHENTICATE_PATIENT_SUCCESS = 'AUTHENTICATE_PATIENT_SUCCESS';
export const AUTHENTICATE_PATIENT_FAILED = 'AUTHENTICATE_PATIENT_FAILED';

export const AUTHENTICATE_SET_PATIENT_DATA = 'AUTHENTICATE_SET_PATIENT_DATA';
export const AUTHENTICATE_SETUP_STEP  = "AUTHENTICATE_SETUP_STEP";
export const AUTHENTICATE_CLINIC_ID  = "AUTHENTICATE_CLINIC_ID";

import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from 'routes/history';
import { REGISTER_PATH } from 'routes/auth/constants';
// import { LOGIN_PATH,REGISTER_PATH } from 'routes/auth/constants';
import { checkUser, logPath } from "state/auth/authActions";

export const requireAuth = (ChildComponent) => {

  class ComposedComponent extends Component {
    handleAuthCheck = () => {
      
      if (!this.props.isLoggedIn) {
        this.props.logPath(history.location.pathname);
        history.push(REGISTER_PATH);
      }
    };

    handleAuthValid = () => {
      this.props.checkUser();
    };

    componentDidMount = () => {
      console.log(this.props);
      this.handleAuthCheck();
      if (this.props.isLoggedIn) {
        this.handleAuthValid();
      }
    };

    componentDidUpdate = () => {
      this.handleAuthCheck();
    };

    render = () => {
      return <ChildComponent {...this.props}/>
    }
  }

  function mapStateToProps(state) {
    return {
      isLoggedIn: state.auth.isLoggedIn,
      userToken: state.auth.token,
    };
  }

  return connect(mapStateToProps, { logPath, checkUser })(ComposedComponent);
}

export const SIGN_IN_USER_API = {
  url: `/auth/login`,
  method: 'post'
};

export const SIGN_OUT_USER_API = {
  url: `/auth/logout`,
  method: 'delete'
};

export const CHECK_USER_API = {
  url: `/auth/current`,
  method: 'get'
};

export const FORGET_PASSWORD_API = {
  url: `/auth/forget_password`,
  method: 'post'
};

export const RESET_PASSWORD_API = {
  url: `/auth/reset_password`,
  method: 'post'
};

export const CHANGE_PASSWORD_API = {
  url: `/auth/change_password`,
  method: 'patch'
};

// apiGeneratePatientAndConsultation
// export const GENERATE_PATIENT_AND_CONSULTATION = {
//   url: `/auth/`
// }

// Patient
// export const FETCH_CURRENT_STAFF_API = {
//   url: `/auth/current`,
//   method: 'get',
// };

import { all } from 'redux-saga/effects';
import lineAttachmentSaga from "state/model/lineAttachments/lineAttachment/lineAttachmentSaga";
import lineAttachmentsSaga from "state/model/lineAttachments/lineAttachments/lineAttachmentsSaga";
import receivedNoticeSaga from "state/model/receivedNotices/receivedNotice/receivedNoticeSaga";
import receivedNoticesSaga from "state/model/receivedNotices/receivedNotices/receivedNoticesSaga";
import productGiftCardSaga from "state/model/productGiftCards/productGiftCard/productGiftCardSaga";
import productGiftCardsSaga from "state/model/productGiftCards/productGiftCards/productGiftCardsSaga";
import giftCardSaga from "state/model/giftCards/giftCard/giftCardSaga";
import giftCardsSaga from "state/model/giftCards/giftCards/giftCardsSaga";
import transactionsSaga from "state/model/transactions/transactions/transactionsSaga";
import patientSaga from 'state/model/patients/patient/patientSaga';

function* modelSaga() {
  yield all([
    lineAttachmentSaga(),
    lineAttachmentsSaga(),
    receivedNoticeSaga(),
    receivedNoticesSaga(),
    productGiftCardSaga(),
    productGiftCardsSaga(),
    giftCardSaga(),
    giftCardsSaga(),
    transactionsSaga(),
    patientSaga(),
  ]);
}

export default modelSaga;

import {
    CREATE_PATIENT,
    CREATE_PATIENT_FAILED,
    CREATE_PATIENT_SUCCESS,
    INIT_PATIENT,
    UPDATE_PATIENT,
    UPDATE_PATIENT_FAILED,
    UPDATE_PATIENT_SUCCESS,
    FETCH_PATIENT,
    FETCH_PATIENT_FAILED,
    FETCH_PATIENT_SUCCESS,
    AUTHENTICATE_PATIENT,
    AUTHENTICATE_PATIENT_SUCCESS,
    AUTHENTICATE_PATIENT_FAILED,
    AUTHENTICATE_SET_PATIENT_DATA,
    AUTHENTICATE_SETUP_STEP,
    AUTHENTICATE_CLINIC_ID,
} from "state/model/patients/patient/constants";

export const initPatient = () => ({
    type: INIT_PATIENT,
    payload: {}
});

export const createPatient = ({ patient }) => ({
    type: CREATE_PATIENT,
    payload: { patient }
});
  
export const createPatientSuccess = ({ patient }) => ({
    type: CREATE_PATIENT_SUCCESS,
    payload: { patient }
});
  
export const createPatientFailed = ({ error }) => ({
    type: CREATE_PATIENT_FAILED,
    payload: { error }
});

export const updatePatient = ({ patient }) => ({
    type: UPDATE_PATIENT,
    payload: { patient }
});

export const updatePatientSuccess = ({ patient }) => ({
    type: UPDATE_PATIENT_SUCCESS,
    payload: { patient }
});

export const updatePatientFailed = ({ error }) => ({
    type: UPDATE_PATIENT_FAILED,
    payload: { error }
});

export const fetchPatient = ({ patientId, target }) => ({
    type: FETCH_PATIENT,
    payload: { patientId, target }
});
  
export const fetchPatientSuccess = ({ patient }) => ({
    type: FETCH_PATIENT_SUCCESS,
    payload: { patient }
});
  
export const fetchPatientFailed = ({ error }) => ({
    type: FETCH_PATIENT_FAILED,
    payload: { error }
});

export const authenticatePatient = (patient, clinicId) => ({
    type: AUTHENTICATE_PATIENT,
    payload: { patient, clinicId }
});

export const authenticatePatientSuccess = ({ patient }) => ({
    type: AUTHENTICATE_PATIENT_SUCCESS,
    payload: { patient }
});

export const authenticatePatientFailed = ({ error }) => ({
    type: AUTHENTICATE_PATIENT_FAILED,
    payload: { error }
});

export const setPatientDate = (key, value) => ({
    type: AUTHENTICATE_SET_PATIENT_DATA,
    payload: {key, value},
});

export const setAuthentificationPatientStep = (step) => ({
    type: AUTHENTICATE_SETUP_STEP,
    payload: { step },
});

export const setClinicId = (clinicId) => ({
    type: AUTHENTICATE_CLINIC_ID,
    payload: { clinicId },
});
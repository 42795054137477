import {
  LIST_TRANSACTIONS,
  LIST_TRANSACTIONS_FAILED,
  LIST_TRANSACTIONS_SUCCESS
} from "state/model/transactions/transactions/constants";

export const listTransactions = ({ transactionableId }) => ({
  type: LIST_TRANSACTIONS,
  payload: { transactionableId }
});

export const listTransactionsSuccess = ({ transactions }) => ({
  type: LIST_TRANSACTIONS_SUCCESS,
  payload: { transactions }
});

export const listTransactionsFailed = ({ error }) => ({
  type: LIST_TRANSACTIONS_FAILED,
  payload: { error }
});

import {
  CREATE_PRODUCT_GIFT_CARD_API,
  INDEX_PRODUCT_GIFT_CARDS_API,
  FETCH_PRODUCT_GIFT_CARD_API,
  DELETE_PRODUCT_GIFT_CARD_API,
  UPDATE_PRODUCT_GIFT_CARD_API
} from "api/model/productGiftCard/constants";
import { generateRoute } from "helpers/routesUtils";
import api from "api/api";

export const apiListProductGiftCards = () => {
  const { url, method } = INDEX_PRODUCT_GIFT_CARDS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateProductGiftCard = ({ productGiftCard }) => {
  const { url, method } = CREATE_PRODUCT_GIFT_CARD_API;

  return api({
    method,
    url,
    data: productGiftCardParams({ productGiftCard }),
  });
};

export const apiFetchProductGiftCard = ({ productGiftCardId }) => {
  const { url, method } = FETCH_PRODUCT_GIFT_CARD_API;

  return api({
    method,
    url: generateRoute(url, productGiftCardId),
  });
};

export const apiUpdateProductGiftCard = ({ productGiftCard }) => {
  const { url, method } = UPDATE_PRODUCT_GIFT_CARD_API;

  return api({
    method,
    url: generateRoute(url, productGiftCard.id),
    data: productGiftCardParams({ productGiftCard }),
  });
};

export const apiDeleteProductGiftCard = ({ productGiftCard }) => {
  const { url, method } = DELETE_PRODUCT_GIFT_CARD_API;

  return api({
    method,
    url: generateRoute(url, productGiftCard.id),
  });
};

const productGiftCardParams = ({ productGiftCard }) => {
  // const {} = productGiftCard;

  return {
    productGiftCard: {

    }
  }
};

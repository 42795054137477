import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticated } from "helpers/authUtils";
import { requireAuth } from "helpers/requireAuth";


class PrivateRoute extends React.Component {
  
  render = () => {
    const { component: Component, roles, ...rest } = this.props;
    console.log('class PrivateRoute extends React.Component {');
    return (
      <Route
        { ...rest }
        render={ props => {
          if (!isUserAuthenticated()) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={ { pathname: '/account/register', state: { from: props.location } } }/>;
          }

          // authorised so return component
          return <Component { ...props } />;
        } }
      />
    );
  }
}

export default requireAuth(PrivateRoute);
